<template>
  <div>
    <b-card
      v-if="statisticsItems"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>
          Thanh toán
          <small>(2)</small>
        </b-card-title>
        <div class="d-flex align-items-center">
          <div class="ctrl-pr-pickr">
            <flat-pickr
              v-model="date_to"
              class="form-control"
              :config="{
                enableTime: true,
                minDate: 'today',
                dateFormat: 'Y-m-d H:i:ss',
              }"
              :placeholder="$t('Mọi lúc')"
            />
            <feather-icon
              size="24"
              icon="CalendarIcon"
              class="ctrl-icon"
            />
          </div>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="add"
            style="white-space: nowrap"
          >
            {{ $t('Xuất báo cáo') }}
          </b-button>
        </div>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsItems"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-card-text class="font-small-3 mb-0">
                  {{ $t(item.subtitle) }}
                </b-card-text>
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-body>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
          <b-form-group style="width: 300px">
            <div class="d-flex align-items-center mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('Tìm kiếm theo tên hoặc mã CTV')"
                />
              </b-input-group>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          ref="promotion-products"
          class="custom-good-table"
          :columns="fields"
          :rows="listDH"
          style-class="vgt-table"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Avatar + Name -->
            <span
              v-if="props.column.field === 'mactv'"
              style="max-width: 240px;"
            >
              <div class="d-flex align-items-center">
                <div class="font-weight-bolder">
                  {{ props.row.mactv }}
                </div>
              </div>
            </span>

            <span
              v-else-if="props.column.field === 'ctv'"
              style="max-width: 140px;"
            >
              <div class="font-weight-bolder">
                {{ props.row.ctv }}
              </div>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'loaictv'">
              <div>
                {{ props.row.loaictv }}
              </div>
            </span>

            <span
              v-else-if="props.column.field === 'doanhthu'"
              class="d-block"
              style="max-width: 140px;"
            >
              {{ unitFormatOriginal(props.row.doanhthu) }}
            </span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'tongck'">
              {{ unitFormatOriginal(props.row.tongck) }}
            </span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'ckdaduyet'">
              {{ unitFormatOriginal(props.row.ckdaduyet) }}
            </span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'dathanhtoan'">
              {{ unitFormatOriginal(props.row.dathanhtoan) }}
            </span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'chuathanhtoan'">
              {{ unitFormatOriginal(props.row.chuathanhtoan) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pageOptions"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  // BTabs,
  // BTab
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
  // BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import general from '@/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    VueGoodTable,
    // BBadge,
    flatPickr,
  },
  mixins: [general],
  data() {
    return {
      rangeDate: {
        string: null,
        from: null,
        to: null,
      },
      date_to: '',
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '49750',
          subtitle: 'Tổng chiết khấu',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DatabaseIcon',
          color: 'light-info',
          title: '49750',
          subtitle: 'Chiết khấu đã duyệt',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ApertureIcon',
          color: 'light-danger',
          title: '500',
          subtitle: 'Đã thanh toán',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'RefreshCcwIcon',
          color: 'light-success',
          title: '49250',
          subtitle: 'Chưa thanh toán',
          customClass: '',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      listDH: [
        {
          mactv: 'SRN0001',
          ctv: 'Hoàng Long',
          loaictv: 'Bán hàng',
          doanhthu: 120000000,
          tongck: 1200000,
          ckdaduyet: 1200000,
          dathanhtoan: 50000000,
          chuathanhtoan: 0,
        },
        {
          mactv: 'SRN0001',
          ctv: 'Thiên Quân',
          loaictv: 'Tiếp thị',
          doanhthu: 120000000,
          tongck: 1200000,
          ckdaduyet: 1200000,
          dathanhtoan: 50000000,
          chuathanhtoan: 0,
        },
        {
          mactv: 'SRN0001',
          ctv: 'Cuồng Long',
          loaictv: 'Tiếp thị',
          doanhthu: 120000000,
          tongck: 1200000,
          ckdaduyet: 1200000,
          dathanhtoan: 50000000,
          chuathanhtoan: 0,
        },
      ],
      // Fields header table
      fields: [
        {
          field: 'mactv',
          label: 'Mã CTV',
          sortable: false,
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          field: 'ctv',
          label: 'CTV',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'loaictv',
          label: 'Loại CTV',
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'doanhthu',
          label: 'Doanh Thu',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'tongck',
          label: 'Tổng chiết khấu',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'ckdaduyet',
          label: 'Chiết khấu đã duyệt',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'dathanhtoan',
          label: 'Đã thanh toán',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'chuathanhtoan',
          label: 'Chưa thanh toán',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      items: [],
      filterSelected: 0,
      filterTabs: [
        {
          id: 6,
          label: 'All orders',
        },
        {
          id: 0,
          label: 'Chưa nhận hàng',
        },
        {
          id: 2,
          label: 'Chưa hoàn tiền',
        },
      ],
      model: {
        time: null,
        status: null,
        category_id: null,
      },
      cateProductOptions: [],
      statusOptions: [
        {
          id: 1,
          label: 'Chưa nhận',
        },
        {
          id: 0,
          label: 'Đã nhận',
        },
      ],
      timeOptions: [
        {
          id: 0,
          label: 'hàng kém chất lượng',
        },
        {
          id: 1,
          label: 'hàng ko như quảng cáo',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        inProgress : 'light-success',
        upComing     : 'light-danger',
        finished     : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 6) return this.listDH
      return this.listDH.filter(item => item.trangthai === this.filterSelected || item.hoantien === this.filterSelected)
    },
  },
  created() {
    this.loadList()
    this.loadListCateProdyct()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/warehouses?site_id=${siteId}&count_page=100&sort[]=created_at,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.perPage = res.data.data.count_page
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.items.length

            const array = []
            res.data.data.items.map(val => {
              if (!array.find(attName => attName.name === val.name)) {
                array.push(val)
              }
            })
            array.map(val => {
              const findData = res.data.data.items.filter(
                ({ name }) => name === val.name,
              )
              const item = {
                id: val.id,
                product_id: val.product_id,
                avatar: val.avatar,
                name: val.name,
                warehouse_id: val.id,
                sku: val.sku,
                price: val.price,
                is_active: val.is_active,
                is_warehouse: val.is_warehouse,
                updated_at: val.updated_at,
                updated_at_utc: val.updated_at,
                // stock: 20,
                // sold: 5,
                barcode: '202207071037',
              }
              let sumStock = 0
              let sumSold = 0
              findData.map(y => {
                sumStock += y.stock
                sumSold += y.sold
                item.stock = sumStock
                item.sold = sumSold
              })
              this.items.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
      }
    },
    // onDateChange(dateFrom, dateTo) {
    // },
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    async loadListCateProdyct() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // eslint-disable-next-line array-callback-return
          res.map(x => {
            const item = {
              id: x.slug,
              label: x.name,
              slug: x.slug,
              idx: x.id,
              children: [],
            }
            if (x.children && x.children.length > 0) {
              // eslint-disable-next-line array-callback-return
              x.children.map(y => {
                const i = {
                  id: y.slug,
                  label: y.name,
                  slug: y.slug,
                  idx: y.id,
                }
                item.children.push(i)
              })
            }
            this.cateProductOptions.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
    resolveUserStatusVariant(numb) {
      if (numb === 0) return 'warning'
      if (numb === 1) return 'success'
      if (numb === 2) return 'warning'
      if (numb === 3) return 'success'
      return 'primary'
    },
    resolveUserStatusTitle(numb) {
      if (numb === 0) return 'Chưa nhận'
      if (numb === 1) return 'Đã nhận'
      if (numb === 2) return 'Chưa hoàn'
      if (numb === 3) return 'Đã hoàn'
      return 'primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.ctrl-pr-pickr
  position: relative

  .ctrl-icon
    position: absolute
    top: 7px
    right: 7px
    color: #d8d6de
    pointer-events: none

.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;
  thead {
    background-color: #f3f2f7
  }
  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
