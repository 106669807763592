<template>
  <section>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Payment Info') }}</span>
        </template>
        <CCollaboratorsInfoPaying />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Lịch sử thanh toán') }}</span>
        </template>
        <CCollaboratorsHistoryPaying />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import CCollaboratorsInfoPaying from './CollaboratorsInfoPaying.vue'
import CCollaboratorsHistoryPaying from './CollaboratorsHistoryPaying.vue'

export default {
  components: {
    BTab,
    BTabs,
    CCollaboratorsInfoPaying,
    CCollaboratorsHistoryPaying,
  },
  mixins: [general],
  data() {
    return {
      rangeDate: {
        string: null,
        from: null,
        to: null,
      },
      date_to: '',
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '49750',
          subtitle: 'Tổng chiết khấu',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DatabaseIcon',
          color: 'light-info',
          title: '49750',
          subtitle: 'Chiết khấu đã duyệt',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ApertureIcon',
          color: 'light-danger',
          title: '500',
          subtitle: 'Đã thanh toán',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'RefreshCcwIcon',
          color: 'light-success',
          title: '49250',
          subtitle: 'Chưa thanh toán',
          customClass: '',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      listDH: [
        {
          mdth: 'SRN0001',
          mdh: 'SON00018',
          khachhang: 'Hoàng Thu Thảo',
          trangthai: 0,
          hoantien: 2,
          tongtien: 135000,
          ngaynhanhang: '29/07/2022, 00:00:00',
          lydotrahang: 'hàng kém chất lượng',
        },
        {
          mdth: 'SRN0001',
          mdh: 'SON00018',
          khachhang: 'Hoàng Thu Thảo',
          trangthai: 1,
          hoantien: 2,
          tongtien: 135000,
          ngaynhanhang: '29/07/2022, 00:00:00',
          lydotrahang: 'hàng kém chất lượng',
        },
        {
          mdth: 'SRN0001',
          mdh: 'SON00018',
          khachhang: 'Hoàng Thu Thảo',
          trangthai: 1,
          hoantien: 3,
          tongtien: 135000,
          ngaynhanhang: '29/07/2022, 00:00:00',
          lydotrahang: 'hàng kém chất lượng',
        },
        {
          mdth: 'SRN0001',
          mdh: 'SON00018',
          khachhang: 'Hoàng Thu Thảo',
          trangthai: 0,
          hoantien: 2,
          tongtien: 135000,
          ngaynhanhang: '29/07/2022, 00:00:00',
          lydotrahang: 'hàng kém chất lượng',
        },
      ],
      // Fields header table
      fields: [
        {
          field: 'madth',
          label: 'Mã đơn trả hàng',
          sortable: false,
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          field: 'madh',
          label: 'Mã đơn hàng',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'khachhang',
          label: 'Khách hàng',
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'trangthai',
          label: 'Trạng thái',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'hoantien',
          label: 'Hoàn tiền',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'tongtien',
          label: 'Tổng tiền',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'ngaynhanhang',
          label: 'Ngày nhận hàng',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'lydotrahang',
          label: 'Lý do trả hàng',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      items: [],
      filterSelected: 0,
      filterTabs: [
        {
          id: 6,
          label: 'All orders',
        },
        {
          id: 0,
          label: 'Chưa nhận hàng',
        },
        {
          id: 2,
          label: 'Chưa hoàn tiền',
        },
      ],
      model: {
        time: null,
        status: null,
        category_id: null,
      },
      cateProductOptions: [],
      statusOptions: [
        {
          id: 1,
          label: 'Chưa nhận',
        },
        {
          id: 0,
          label: 'Đã nhận',
        },
      ],
      timeOptions: [
        {
          id: 0,
          label: 'hàng kém chất lượng',
        },
        {
          id: 1,
          label: 'hàng ko như quảng cáo',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        inProgress : 'light-success',
        upComing     : 'light-danger',
        finished     : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 6) return this.listDH
      return this.listDH.filter(item => item.trangthai === this.filterSelected || item.hoantien === this.filterSelected)
    },
  },
  created() {
    this.loadList()
    this.loadListCateProdyct()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/warehouses?site_id=${siteId}&count_page=100&sort[]=created_at,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.perPage = res.data.data.count_page
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.items.length

            const array = []
            res.data.data.items.map(val => {
              if (!array.find(attName => attName.name === val.name)) {
                array.push(val)
              }
            })
            array.map(val => {
              const findData = res.data.data.items.filter(
                ({ name }) => name === val.name,
              )
              const item = {
                id: val.id,
                product_id: val.product_id,
                avatar: val.avatar,
                name: val.name,
                warehouse_id: val.id,
                sku: val.sku,
                price: val.price,
                is_active: val.is_active,
                is_warehouse: val.is_warehouse,
                updated_at: val.updated_at,
                updated_at_utc: val.updated_at,
                // stock: 20,
                // sold: 5,
                barcode: '202207071037',
              }
              let sumStock = 0
              let sumSold = 0
              findData.map(y => {
                sumStock += y.stock
                sumSold += y.sold
                item.stock = sumStock
                item.sold = sumSold
              })
              this.items.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
      }
    },
    // onDateChange(dateFrom, dateTo) {
    // },
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    async loadListCateProdyct() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // eslint-disable-next-line array-callback-return
          res.map(x => {
            const item = {
              id: x.slug,
              label: x.name,
              slug: x.slug,
              idx: x.id,
              children: [],
            }
            if (x.children && x.children.length > 0) {
              // eslint-disable-next-line array-callback-return
              x.children.map(y => {
                const i = {
                  id: y.slug,
                  label: y.name,
                  slug: y.slug,
                  idx: y.id,
                }
                item.children.push(i)
              })
            }
            this.cateProductOptions.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
    resolveUserStatusVariant(numb) {
      if (numb === 0) return 'warning'
      if (numb === 1) return 'success'
      if (numb === 2) return 'warning'
      if (numb === 3) return 'success'
      return 'primary'
    },
    resolveUserStatusTitle(numb) {
      if (numb === 0) return 'Chưa nhận'
      if (numb === 1) return 'Đã nhận'
      if (numb === 2) return 'Chưa hoàn'
      if (numb === 3) return 'Đã hoàn'
      return 'primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;
  thead {
    background-color: #f3f2f7
  }
  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
